import { css } from '@emotion/react'
import { debounce } from 'lodash'
import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { UseSiteSearchData } from 'react-datocms/use-site-search'

import { useEscKeyFunction } from '@/hooks/useEscKeyFunction'
import { bezier } from '@/theme/mixins'

import { useSearchContext } from '../../contexts/searchContext'
import { useDatoSearch } from '../../hooks/useDatoSearch'

interface Props extends ComponentPropsWithoutRef<'input'> {
  onDataUpdate: Dispatch<SetStateAction<UseSiteSearchData | undefined>>
}

export const SearchField = ({
  onDataUpdate,
  ...props
}: Props): JSX.Element => {
  const { searchIsOpen, setSearchIsOpen, setIsSearching, setIsEmpty } =
    useSearchContext()
  const [value, setValue] = useState('')
  const { results, query, setQuery } = useDatoSearch()
  const queryDebouncer = useRef(
    debounce(value => {
      setQuery(value)
    }, 500)
  ).current
  useEffect(() => {
    return () => {
      queryDebouncer.cancel()
    }
  }, [queryDebouncer])
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    queryDebouncer(e.target.value)
  }
  useEffect(() => {
    if (value.length === 0) {
      setIsSearching(false)
      setIsEmpty(true)
    } else if (results === undefined || value !== query) {
      setIsEmpty(false)
      setIsSearching(true)
    } else {
      setIsEmpty(false)
      setIsSearching(false)
    }
  }, [value, results, setIsSearching, setIsEmpty, query])
  useEffect(() => {
    onDataUpdate(results)
  }, [onDataUpdate, results])
  const handleClearSearch = () => {
    setValue('')
    setSearchIsOpen(false)
  }
  useEscKeyFunction(() => {
    handleClearSearch()
  })
  const inputRef = useRef<HTMLInputElement | null>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  useEffect(() => {
    if (searchIsOpen) {
      timeoutRef.current = setTimeout(() => {
        inputRef.current?.focus()
      }, 300)
    }
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [searchIsOpen])
  const styles = {
    container: css`
      display: flex;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 1rem);
        height: 2px;
        background: currentColor;
      }
    `,
    input: css`
      width: 100%;
      height: 100%;
      color: transparent;
      caret-color: #fff;
      appearance: none;
      background: transparent;
      border: none;
      padding: 0.125em 0;
      padding-right: 1rem;
      pointer-events: none;
      &::-webkit-search-cancel-button {
        display: none;
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.333);
      }
      ${searchIsOpen &&
      css`
        pointer-events: all;
      `}
    `,
    cancelButton: css`
      display: flex;
      box-sizing: content-box;
      position: absolute;
      top: 50%;
      right: 0;
      width: 0.5em;
      height: 0.5em;
      padding: 1rem;
      stroke-width: 2;
      stroke: currentColor;
      fill: transparent;
      pointer-events: none;
      transform: translateY(-50%) scale3d(0.99, 0.99, 1);
      transition: transform 500ms ${bezier.bounce};
      @media (hover: hover) {
        &:hover {
          transform: translateY(-50%) scale3D(1.1, 1.1, 1);
        }
      }
      ${searchIsOpen &&
      css`
        pointer-events: all;
      `}
      svg {
        width: 100%;
        height: 100%;
        path {
          stroke-width: 2;
        }
      }
    `,
    valueDisplayer: css`
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      line-height: 0.8;
      color: currentColor;
      pointer-events: none;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <span css={[styles.input, styles.valueDisplayer]}>{value}</span>
      <input
        css={styles.input}
        type="search"
        value={value}
        onChange={e => handleChange(e)}
        placeholder="Search"
        ref={inputRef}
        tabIndex={searchIsOpen ? 0 : -1}
      />
      <button
        css={styles.cancelButton}
        onClick={handleClearSearch}
        tabIndex={searchIsOpen ? 0 : -1}
        aria-label="Close Search"
      >
        <svg viewBox="0 0 24 24">
          <path
            d="M1 23L12 12L1 1"
            vectorEffect="non-scaling-stroke"
          />
          <path
            d="M23 1L12 12L23 23"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </button>
    </div>
  )
}
